import React, { useContext } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import lockOverlay from "@images/lock-overlay-75.svg"
import { FirebaseContext } from "../Firebase"
import adOg from "@images/logos/ad-original-logo.png"
import PropTypes from "prop-types"
import { Link  } from "gatsby"
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import AudioPlayerContext from '../audioplayer/AudioPlayerContext'
import { usePageContext }  from '@hooks/usePageContext'
import spicinessZero from "@images/spiciness-0.png"


const CustomLink =  styled(Link)`
  width:85%;
  display: flex;
  text-decoration: none;
`

const ResultItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  background-color: white;
  position: relative;
  padding: 10px 10px;
  border-radius: 5px;
`

const ResultImage = styled.div`
  display: flex;
  width:75px;
  min-width:75px;
  position: relative;
  min-height: 100px;
  margin-right: 8px;
  overflow: hidden;
`

const StoryMainInfo = styled.div`
  margin: 0px;
  padding-top:15px;
`

const StoryTitle = styled.h2`
  font-size: 1.15rem !important;
  line-height: 1.3rem;
  font-family:${props => props.theme.mainFontHeading};
  margin: 0px 0px 5px 0px;
  @media (max-width: 330px) {
    font-size: 1rem !important;
  }
  text-decoration: none;
`

const OverlayLock = styled.img`
  position: absolute;
  border-radius:3px;
  height: 100px;
  z-index: 15;
  margin-right:200px;
`

const StoryInfoWrapper = styled.div`
  display:flex;
  width:75%;
`

const StorySubtitle = styled.h4`
  font-size: 11px;
  margin: 0px 0px;
  text-transform: uppercase;
  font-family: 'Poppins';
  color: #A68C8A;
  font-weight: 500;
  line-height: 14px;
`


const OriginalContentImg = styled.img`
  height: 15px;
  width: 15px;
  top: 5px;
  position: absolute;
  left: 5px;
  z-index: 1;
`

const PlayButtonWrapper = styled.div`
  width:15%;
  display:flex;
  align-items:center;
  justify-content: center;
`

const PlayButton = styled.div`
  height:33px;
  width:33px;
  border-radius: 50%;
  background-color:  #f19d9a;
  display:flex;
  align-items:center;
  justify-content: center;
`

const PlayIcon =  styled.div`
  box-sizing: border-box;
  border-style: solid;
  border-width: 7px 0px 7px 10px;
  border-color: transparent transparent transparent white;
  margin-left: 3px;
`

const StoryMeta = styled.div`
  display:flex;
  position: absolute;
  bottom: 10px;
`

const Spiciness = styled.img`
  height: 20px;
`

const Duration = styled.div`
  font-family: 'Poppins';
  font-size: 12px;
  margin: 2px 0 0 10px;
`

  function getDuration(seconds){
    let date = new Date(0);
    date.setSeconds(seconds);
    if(seconds > 3580) return date.toISOString().substr(12, 7);
    return date.toISOString().substr(14, 5);
  }

 const ListViewItem = props => {
  const { user, firebase } = useContext(FirebaseContext)
  const {setCurrentStory, setPlayerMode} = useContext(AudioPlayerContext);
  const { t } = useTranslation();
  const { lang } = usePageContext()
  const { id,slug, title, storyCover, thumbnail, voices, mainFantasy, premiumContent, duration, classicAudioStructure} = props;

  async function fetchAudio(audioRef) {
    const storageRef = await firebase.storage.ref().child(audioRef);
    try {
      const url = await storageRef.getDownloadURL()
      return url
    } catch (err) {
      console.log(err)
    }
  }
  
  const handlePlayClick = async () => { 
    // get path depending on which audio structure is used
    const path = `sleep/${id}/${lang}/${classicAudioStructure ? 'sample' : 'free'}/audio${classicAudioStructure ?'' : '-30' }.mp3`
    const dataUrl =  await fetchAudio(path)
    setPlayerMode("small")
    setCurrentStory({
      id,
      storyTitle: title,
      voiceName: `${voices[0].name}${voices.length > 1 ? ` + ${voices[1].name}` :""}`,
      storyCover: storyCover,
      audioRef: dataUrl,
      type: classicAudioStructure ? 'sample' : '30-min',
      showRatingForm: false,
      alreadyRated: false,
      slug: slug,
      sessionId: uuidv4(),
      autoplay:true,
      thumbnail,
      duration:classicAudioStructure ? duration : "1800",
      lang
    })
    
  }


  return (
    <ResultItem>
      <CustomLink to={`${(t('urls.sleepEpisode'))}${slug}`}>
        <ResultImage>
          {((premiumContent === "true" && user && !user.isSubscriber) ||
            (premiumContent === "true" && !user)) && (
            <OverlayLock src={lockOverlay} />
          )}
          <OriginalContentImg src={adOg}></OriginalContentImg>
          <GatsbyImage
            image={thumbnail}
            alt={`Story Cover Image for: ${title}`}
            style={{borderRadius:"4px"}}
          />
        </ResultImage>
        <StoryInfoWrapper>

          <StoryMainInfo>
            <StoryTitle>{title}</StoryTitle>
            <StorySubtitle >
              {voices[0]?.name !== "placeholder" &&
                <>
                  {" "}
                  {voices[0]?.name}
                  {voices[1]
                    ? ` + ${voices[1]?.name} • `
                    : ` • `}
                  {mainFantasy}
                </>
              }
            </StorySubtitle>
          </StoryMainInfo>
          <StoryMeta>
            <Spiciness src={spicinessZero}/>
            <Duration>{getDuration(duration)}</Duration>
          </StoryMeta>
        </StoryInfoWrapper>
      </CustomLink>
      <PlayButtonWrapper>
        <PlayButton onClick={()=> handlePlayClick()}><PlayIcon/></PlayButton>
      </PlayButtonWrapper>
    </ResultItem>
  )
}



ListViewItem.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired, 
  title: PropTypes.string.isRequired,
  sampleAudioRef: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  voices: PropTypes.array.isRequired,
  mainFantasy: PropTypes.string.isRequired,
  premiumContent: PropTypes.string.isRequired,
}

export default ListViewItem
