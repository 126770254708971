import React, {useEffect, useContext, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, navigate, graphql } from "gatsby"
import { FirebaseContext } from '@components/Firebase';
import AudioPlayerContext from '@components/audioplayer/AudioPlayerContext';
import {BackButton, Button, StoryTitleWave, FantasyTag, FavHeart} from '@common';
import LangSwitcher from  '@multilang/LangSwitcher'
import Text from  '@typo/Text'
import StoryTitleBig from  '@typo/StoryTitleBig'
import StoryMetaBig from  '@typo/StoryMetaBig'
import Seo from "@components/Seo";
import MoreLikeThis from "../../components/sleep/MoreLikeThisSleep"
import { useTranslation } from 'react-i18next'
import { usePageContext }  from '@hooks/usePageContext'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import play from "@images/play.svg";
import playFilled from "@images/play-filled.svg";
import lock from "@images/lock.svg";
import Header from "@menus/header"


const StoryDetailWrapper = styled.div`
  position: relative;
  background-color:${props => props.theme.backgroundColor};
`;


const StoryDetailContentWrapper = styled.div`
  padding: 0px 20px 20px 20px;
  overflow:hidden ;
  h2{
    font-size:30px;
    font-weight:700px;
  }
`

const ButtonIcon  = styled.img`
  height:20px;
  padding-right:10px;
  .st0{fill:#a2e61c;}
`

const ButtonIconWrapper = styled.div`
  z-index:20;
  display:flex;
  align-items: center;
  height: 25px;
  justify-content: center;
  a{
    text-transform:uppercase;
  }
`

const HeartWrapper = styled.div`
  top: 30px;
  position: absolute;
  z-index: 5;
  right: 30px;
  @media (min-width: 600px) {
    right:auto;
    top:auto;
    margin-top: 30px;
    margin-left: 540px;
    z-index: 15;
    position: absolute;
    height: 30px;
    width: 30px;
  }
`

const SlideContainer = styled.div`  
  padding:20px 0px 10px 0px;
  a{
    text-decoration:none; 
  }
`

const SwiperLink = styled(Link)`
  width:auto;
  padding: 10px 0px;
`

const MetaLink = styled(Link)`
  text-decoration:none;
`

const LanguageWrapper = styled.div`
  display:flex;
  align-items: baseline;
  justify-content: start;
`

const LangText = styled.p`
  padding-right:20px;
`

const ContentType = styled.span`
  font-size:12px;
  margin: 0px 0px;
  text-transform:uppercase;
  font-family: 'Poppins';
  border-bottom:2px solid #F19D9A;
  @media (min-width: 500px) {
    font-size:16px;
  }
`

const VoicesWrapper = styled.div`
  display:flex;
  align-items: center;
  & > a {
    text-decoration:none;
  }
`

const ReadMoreToggle = styled.p`
  background:${props => props.theme.backgroundColor};
  position:absolute;
  bottom: 0;
  line-height:26px;
  right:0;
  padding:0;
  margin:0;
  font-weight:300;
  font-size:14px;
  text-transform:uppercase;
  text-decoration: underline;
  &:before {
    content: "";
    z-index:5;
    text-align: right;
    position: absolute;
    bottom: ${props => props.showMoreText ? '-100%' : 0};
    left: -100%;
    width: 100%;
    height: 26px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), ${props => props.theme.backgroundColor} 85%);
  }
`;


const SummaryWrapper = styled.div`
  position:relative;
  padding:0;
  ${props => props.showMoreText &&" padding-bottom:30px"};
  transition: all 0.2s ease;
  margin:0;
  overflow:hidden;
  height:100% ;
`;

const Summary = styled.p`
  height:${props => props.showMoreText ? '100%' :'78px'};
  padding:0;
  margin:0;
  line-height:26px;
`
  const params = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    freeMode: true,

  }

const SleepEpisode = (props) => {
  const {data:{sleepEpisode:{ id,  localImage, storyId, seoTitle, seoDescription }}} = props

  const {data:{sleepEpisode}} = props
  const {pageContext:{lang}} = props
  const classicAudioStructure = props.data.sleepEpisode[lang].classicAudioStructure
  const storyCover = localImage.cover.gatsbyImageData
  const absoluteImageUrl = storyCover?.images?.fallback.src
  const {firebase, user} = useContext(FirebaseContext);
  const {setCurrentStory, setPlayerMode} = useContext(AudioPlayerContext);
  const [filled, setFilled] = useState(false);
  const [showMoreText, setShowMoreText] = useState(false);
  const { t } = useTranslation()
  const { languages } =  usePageContext() 
  const episodeDuration  = props.data.sleepEpisode[lang].duration
  

  
 
  useEffect(() => {
    const heartStatus = async () => {
      const filled = await firebase.checkForHeartStatus(storyId,user.uid)
      setFilled(filled)
    }
    if(firebase && user) {
      heartStatus()
    } 
  },[user,firebase,storyId])

  async function fetchAudio(audioRef) {
    const storageRef = await firebase.storage.ref().child(audioRef);
    try {
      const url = await storageRef.getDownloadURL()
      return url
    } catch (err) {
      console.log(err)
    }
  }

  const getPlayButtonIcon = () => {

    const locked = {
      text:t('story-detail.unlockButton'),
      icon:lock
    }

    const playable = {
      text:t('story-detail.playButton'),
      icon:play
    }

    const playLong = {
      text:t('sleepEpisode.playLong'),
      icon:play
    }

    if(sleepEpisode[lang].premiumContent === "true"){
      if(user?.isSubscriber){
        if(sleepEpisode[lang].classicAudioStructure){
          return playable 
        } else {
          
          return playLong
        }
      } else {
        return locked
      }
    }

    if(sleepEpisode[lang].premiumContent === "false"){
      if(user){
           if(sleepEpisode[lang].classicAudioStructure){
          return playable 
        } else {
          return playLong
        }
      } else {
        return locked
      }
    }
  }

  const handlePlayClick = async (buttonType) => { 
    if(buttonType === 'long' && !user){ navigate(t('urls.register')) }// bail if not logged in
    else {
      
      const path = classicAudioStructure 
        ? (buttonType === "long" 
          ? `/${sleepEpisode[lang].premiumContent === 'false' ? 'free' : 'premium' }/audio.mp3`
          : `/sample/audio.mp3`)
        : `/${sleepEpisode[lang].premiumContent === 'false' || buttonType === "short" ? 'free' : 'premium' }/audio${buttonType === 'long' ? '-60' : '-30'}.mp3`
     
      const dataUrl =  await fetchAudio(`sleep/${sleepEpisode.id}/${lang}${path}`)
      const type = buttonType === "long"  
        ? classicAudioStructure ? 'full' : '60-min' 
        : classicAudioStructure ? 'sample' : '30-min' 

      const duration = buttonType === "long" 
        ? episodeDuration
        : classicAudioStructure ? '1800' : episodeDuration

      setPlayerMode("fullscreen")
      setCurrentStory({
        id,
        storyCover:localImage.cover.gatsbyImageData,
        slug:sleepEpisode[lang].slug,
        lang,
        storyTitle: sleepEpisode[lang].title,
        voiceName: `${sleepEpisode[lang].voices[0].name}${sleepEpisode[lang].voices.length > 1 ? ` + ${sleepEpisode[lang].voices[1].name}` :""}`,
        audioRef: dataUrl,
        type,
        sessionId: uuidv4(),
        autoplay:true,
        thumbnail:sleepEpisode.localImage.thumbnail.gatsbyImageData,
        duration
      })
    }
  }

  function handleAddFavoriteClick(){
    if(user){
      firebase.toggleFavorite(storyId,user.uid);
      setFilled(!filled);
    }
    else{
      navigate(t('urls.login'));
    }
  }
  return (
  <>
  <Header location={props.location}/> 
  <Seo description={seoDescription} title={seoTitle} image={absoluteImageUrl}/>
    <BackButton absolute marginTop marginLeft white/>
    <HeartWrapper onClick={() => handleAddFavoriteClick()} user={user}><FavHeart filled={filled}/> </HeartWrapper>
    <StoryDetailWrapper >
      <div style={{ width: '100%',display:"flex" }}>
        <GatsbyImage
          image={storyCover}
          alt={`${sleepEpisode[lang].title} Erotic Audio Story Audiodesires - ${sleepEpisode.mainTag[lang].tag}`}
          title={sleepEpisode[lang].title} />
      </div>
      <StoryTitleWave >
        <ContentType >{sleepEpisode.sleepGenre[lang].genre}</ContentType >
        <StoryTitleBig>{sleepEpisode[lang].title}</StoryTitleBig>
          <StoryMetaBig>
            <MetaLink to={`${t('urls.scenario')}${sleepEpisode.scenario[lang].slug}/`}> {sleepEpisode.scenario[lang].scenario}</MetaLink> •
            <MetaLink to={`${t('urls.sleepTag')}${sleepEpisode.mainTag[lang].slug}/`}> {sleepEpisode.mainTag[lang].tag}</MetaLink>
          </StoryMetaBig>
      </StoryTitleWave>
    </StoryDetailWrapper>
    <StoryDetailWrapper>
      <StoryDetailContentWrapper>
      <SlideContainer className="swiper-container">
        <Swiper {...params} style={{overflow: 'visible'}}>
          {sleepEpisode.tags.filter(tag => tag.languages.includes(lang)).map(edge =>(
            <SwiperSlide style={{width: "auto"}} key={edge.id}>
              <SwiperLink className="swiper-slide" to={`${t('urls.sleepTag')}${edge[lang].slug}/`} key={edge.id}>
                <FantasyTag key={edge.id}>
                  {edge[lang].tag}
                </FantasyTag>
              </SwiperLink>
            </SwiperSlide>
          ))} 
        </Swiper>
      </SlideContainer>
        <SummaryWrapper showMoreText={showMoreText}>
          <Summary showMoreText={showMoreText} dangerouslySetInnerHTML={{ __html: `${user ? sleepEpisode[lang].summary : sleepEpisode[lang].seoSummary}`}}/>
          <ReadMoreToggle showMoreText={showMoreText} onClick={()=> setShowMoreText(!showMoreText)}>
            {showMoreText ? t('story-detail.readLess') : t('story-detail.readMore') }
          </ReadMoreToggle>
        </SummaryWrapper>
        {sleepEpisode[lang].voices[0]?.name !== 'placeholder' &&
          <VoicesWrapper>
          <Text mr="20px">Voices:</Text>
          {sleepEpisode[lang]?.voices.map((voice) => (
            <Link to={`${t('urls.voice')}${voice.slug}/`} key={uuidv4()}> <FantasyTag>{voice.name}</FantasyTag></Link>
          ))}
          </VoicesWrapper>
        }
        {languages.length > 1 && 
          <LanguageWrapper>
            <LangText>{t('story-detail.language')}</LangText>
            <LangSwitcher border={true} small={true} smallBorder={true}/>
          </LanguageWrapper>
        }
      
        <Button marginTop  block onClick={() => handlePlayClick('long')}>
          <ButtonIconWrapper>
            <ButtonIcon src={getPlayButtonIcon().icon}/>
            <p>{getPlayButtonIcon().text}</p>
          </ButtonIconWrapper>
        </Button>
        <Button marginTop inverted border block onClick={() => handlePlayClick('short')}>
          <ButtonIconWrapper>
            <ButtonIcon src={playFilled}/>
            <p>{sleepEpisode[lang].classicAudioStructure ? t('story-detail.playSampleButton') :t('sleepEpisode.playShort')}</p>
          </ButtonIconWrapper>
        </Button>
        <MoreLikeThis 
          similarStories={props.data.similarStories} 
          seriesStories={props.data.seriesStories ? props.data.seriesStories : [] }
        /> 
      </StoryDetailContentWrapper>
    </StoryDetailWrapper>
    </>
  )
}


export default SleepEpisode

// this way will inject query into props.data
export const query = graphql`
query EpisodeQuery(
  $storyId: String!, 
  $isEn: Boolean!, 
  $isEs: Boolean!,
  $seriesStoriesIds: [String!],
  $similarStoriesIds: [String!], 
  $isDe: Boolean!)
  {
  sleepEpisode(id: {eq: $storyId}) {
    id
    en @include(if: $isEn)  {
      accent {
        accent
      }
      voices{
        id
        name
        gender
        slug
      }
      duration
      featured
      premiumContent
      published
      metaDescription
      seoTitle
      seriesEpisodes
      slug
      summary
      seoSummary
      title
      classicAudioStructure
    }
    de @include(if: $isDe)  {
      accent {
        accent
      }
      voices{
        id
        name
        gender
        slug
      }
      duration
      featured
      premiumContent
      published
      metaDescription
      seoTitle
      seriesEpisodes
      slug
      summary
      seoSummary
      title
      classicAudioStructure
    }
    es @include(if: $isEs)  {
      accent {
        accent
      }
      voices{
        id
        name
        gender
        slug
      }
      duration
      featured
      premiumContent
      published
      metaDescription
      seoTitle
      seriesEpisodes
      slug
      summary
      seoSummary
      title
      classicAudioStructure
    }
    scenario {
      en {
        scenario
        slug
      }
      es {
        scenario
        slug
      }
      de {
        scenario
        slug
      }
    }
    sleepGenre {
      id
      en  @include(if: $isEn) {
        genre
      }
      es  @include(if: $isEs) {
        genre
      }
      de  @include(if: $isDe) {
        genre
      }
    }
    localImage {
      cover: childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED,
          width: 600, 
          height: 600, 
          layout: CONSTRAINED)
      }
    }
    localImage {
      thumbnail: childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED,
          width: 120, 
          layout: CONSTRAINED)
      }
    }
    mainTag {
      en {
        tag
        slug
      }
      es {
        tag
        slug
      }
      de {
        tag
        slug
      }
    }
    tags {
      id
      languages
      en @include(if: $isEn){
        tag
        slug
      }
      es @include(if: $isEs){
        tag
        slug
      }
      de @include(if: $isDe){
        tag
        slug
      }
    }
  }
  similarStories: allSleepEpisode(filter: {id: {in: $similarStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisSleepEpisodeData
      }
    }
  }
  seriesStories: allSleepEpisode(filter: {id: {in: $seriesStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisSleepEpisodeData
      }
    }
  }
}
`;  