import React , {useState} from "react"
import styled from 'styled-components'
import Text from  '@typo/Text'
import { useTranslation } from 'react-i18next'
import { usePageContext }  from '@hooks/usePageContext'
import SleepListView from '@components/sleep/SleepListView'

const RecommendationWrapper = styled.div`
  margin-top:30px;
  p{
    margin:0px;
  }
`

const SimilarStoriesWrapper = styled.div`
  padding-top:20px;
  margin-top:0;
`

const TabsWrapper = styled.div`
  display:flex;
  border-bottom: solid 4px #f19d9a75;
`

const Tabs = styled.div`
  ${props => props.activeTab ? 'border-bottom:solid 4px #F19D9A': ''};  
  padding-bottom:5px;
  margin-bottom:-4px;
  padding-right:10px;
  &:not(:first-child){
    padding-left:10px;
  }
`

const TabsText = styled(Text)`
  text-transform: uppercase;
  line-height:1.1;
`

const MoreLikeThis = (props) => {
  const { similarStories, seriesStories} = props;
  const [activeTab, setActiveTab] = useState(seriesStories.edges.length > 0 ?'series' :'similar');
  const { t } = useTranslation()
  const { lang } = usePageContext()

  return(
    <RecommendationWrapper>
      <TabsWrapper>
        {seriesStories.edges.length > 0 && <Tabs activeTab={ activeTab === 'series'} onClick={() => setActiveTab('series')}><TabsText>{t('more-like-this.seriesStories')}</TabsText></Tabs> }
        <Tabs activeTab={ activeTab === 'similar'} onClick={() => setActiveTab('similar')}><TabsText>{t('more-like-this.moreLikeThis')}</TabsText></Tabs>
      </TabsWrapper>
      <SimilarStoriesWrapper>
        {activeTab === 'similar' && 
          <>
            {similarStories && similarStories.edges.map(edge =>(
              <>
                <SleepListView
                  id={edge.node.id}
                  slug={edge.node[lang].slug}
                  title={edge.node[lang].title}
                  storyCover={edge.node.localImage.cover.gatsbyImageData}
                  thumbnail={edge.node.localImage.thumbnail.gatsbyImageData}
                  duration={edge.node[lang].duration}
                  voices={edge.node[lang].voices}
                  mainFantasy={edge.node.mainTag[lang].tag}
                  premiumContent={edge.node[lang].premiumContent}
                  classicAudioStructure={edge.node[lang].classicAudioStructure}
                  key={edge.node.id}
                />
              </>
            ))}
          </>
        }
   {/*      {activeTab === 'series' && 
          <>
            {seriesStories && seriesStories.edges.map(edge =>(
              <ListViewItem
                id={edge.node.id}
                slug={edge.node[lang].slug}
                title={edge.node[lang].title}
                sampleAudioRef={edge.node[lang].sampleAudioRef}
                storyCover={edge.node.localImage.coverImage.gatsbyImageData}
                thumbnail={edge.node.localImage.thumbnail.gatsbyImageData}
                duration={edge.node[lang].duration}
                voices={edge.node[lang].voices}
                mainFantasy={edge.node.mainFantasy[lang].fantasy}
                spiciness={edge.node.spiciness}
                premiumContent={edge.node[lang].premiumContent}
                audiodesiresOriginal={edge.node.audiodesiresOriginal}
                key={edge.node.id}
              />
            ))}
          </>
        } */}
      </SimilarStoriesWrapper>
    </RecommendationWrapper>
  )
}

export default MoreLikeThis;

    